<template>
<span>Als u gebruik maakt van betalingen Synchronisatie, dan zie u hier de betalingen die zijn gemaakt, als deze overeenkomen met de bron dan synchronizeren wij deze terug naar de bron.</span>
    <Overview 
        ref="Overview" 
        :moduleName="'Factuur Bedrag'"
        :moduleNamePlural="'Factuur Bedragen'"
        :sort="['external_date']"
        :sortOrder="'-1'"
        :itemService="itemService"
        :itemFormRef="itemFormRef"
        :disableNewButton="true"
        :disableEdit="false"
        :disableDelete="true"
    >
        <template #columns>
            <Column field="connection.name" header="Koppeling"></Column>
            <Column field="reference" header="Factuurnummer" :sortable="true"></Column>
            <Column field="amount_dc" header="Totaal (Boekhoudpakket)" :sortable="true">
                <template #body="slotProps">
                    <template  v-if="slotProps.data.amount_dc">
                        &euro; {{formatCurrency(slotProps.data.amount_dc)}}
                    </template>
                </template>
            </Column>
            <Column field="description" header="Omschrijving" :sortable="true"></Column>
            <Column field="invoice.identifier" header="Factuurnummer (Bron)" :sortable="true"></Column>
            <Column field="invoice.total_incl_vat" header="Totaal bedrag (Bron)">
                <template #body="slotProps">
                    <template  v-if="slotProps.data.invoice.total_incl_vat">
                        &euro; {{formatCurrency(parseFloat(slotProps.data.invoice.total_incl_vat))}}
                    </template>
                    <template  v-else>
                        &euro; {{formatCurrency(parseFloat(slotProps.data.invoice.total_excl_vat) + parseFloat(slotProps.data.invoice.total_vat))}}
                    </template>
                </template>
            </Column>
            <Column field="invoice.total_excl_vat" header="Totaal amount (Bron)" :sortable="true">
                <template #body="slotProps">
                    <template  v-if="slotProps.data.invoice.total_vat">
                        &euro; {{formatCurrency(slotProps.data.invoice.total_excl_vat)}}
                    </template>
                </template>
            </Column>
            <Column field="invoice.total_vat" header="Totaal btw (Bron)" :sortable="true">
                <template #body="slotProps">
                    <template  v-if="slotProps.data.invoice.total_vat">
                        &euro; {{formatCurrency(slotProps.data.invoice.total_vat)}}
                    </template>
                </template>
            </Column>
            <Column field="external_date" header="Datum" :sortable="true">
                <template #body="slotProps">
                    {{formatDate(slotProps.data.external_date, 'shortDate')}}
                </template>
            </Column>
            <Column field="invoice.source_status" header="Source Status" :sortable="true"></Column>
        </template>
            
        <template #actions>
            <Column :exportable="false" style="width: 1rem">
            </Column>
        </template>

        
        <template #form>
            <invoiceFormComponent ref="ItemForm" :itemData="item" :isAdmin="isAdmin" :debug="debug"/>
        </template>
    </Overview>
</template>

<script>
import Overview from '@/components/Overview.vue';
import Column from 'primevue/column';
import invoiceFormComponent from '@/components/invoice/InvoiceFormComponent';
import invoicePaymentService from '@/services/InvoicePaymentService';

import store from '@/store/user'

export default {
    components: {
        Overview,
        Column,
        invoiceFormComponent
    },
    data() {
        return {
            item: {},
            itemService: invoicePaymentService,
            itemFormRef: null,
            loading: [],
            debug: true
        }
    },
    computed: {
        isAdmin () {
            return store.getters.isAdmin
        }
    },
    methods: {
        editItem(item, debug = true) {
            this.debug = debug;
            this.itemLoading = true;
            this.itemFormRef.reset();

            this.itemService.show(item.id).then(response => {
                this.itemLoading = false;
                this.item = response.data
                this.itemFormRef.setData(this.item);
            }).catch(error => {
                this.toastError(error)
                console.log('error', error) // create notification-toaster for user error
            })
        },
    },
    mounted() {
        this.itemFormRef = this.$refs.ItemForm;
    }
}
</script>

<style scoped>

    a.fa-stack {
        cursor: pointer;
    }

</style>