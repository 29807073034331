import http from '@/http-common'
import {ApiService} from '@/services/ApiService'

const URL = '/api/invoice-payment'

class InvoicePaymentService extends ApiService {
    constructor(){
        super()
    }
    getUrl(){
        return URL
    }
}

export default new InvoicePaymentService()